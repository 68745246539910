/* //////////////////hero section ?////////////////////////////////////// */

/* ////////////////////////////////// */
.react-tel-input .form-control {
  width: 38px !important;
  padding-left: 0!important;
}
.react-tel-input .flag-dropdown {
  width: 38px !important;
}
.container {
  margin: 20px;
}
textarea{
  resize: none;
}
.custom-select-icon-contact  {
  position: absolute;
  right: 10px;
  top: 40%;
  transform: translateY(-50%);
  z-index: 1;
}
.custom-select {
  position: relative;
  width: 100px;
  cursor: pointer;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  overflow-x: scroll;
  /* margin-top: 150px; */
  /* height: 200px; */
}

.personal-details,
.personal-details2 {
  color: #777;
  // font-family: Inter;
  font-size: 11.552px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 17.329px */
}
.company-details,
.company-details2 {
  color: #777;
  // font-family: Inter;
  font-size: 11.552px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 17.329px */
}
.custom-select::-webkit-scrollbar {
  display: none;
}

.selected-item {
  display: flex;
  align-items: center;
  padding: 8px;
  /* margin-top: 10px; */
  /* background-color: #f8f8f8; */
}

.flag-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.select-items {
  display: none;
  position: absolute;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  border-top: none;
  width: 100%;
  z-index: 1;
  /* height: 200px;
    width: 200px; */
}

.select-items div {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.select-items div:hover {
  background-color: #f1f1f1;
}

/* //////////////////  ?////////////////////////////////////// */

.eclips {
  bottom: 0%;
}

.get-in {
  width: 100%;
  color: #2a2f35;
  font-variant-numeric: ordinal;
  font-feature-settings: "dlig" on;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 54.6px */
}

.company-name {
}
.company-name h1 {
  color: #512e05;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 54.6px */
}
.company-name p {
  color: #78808a;
  // font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  width: 279px;
}

.reach-out {
}
.reach-out h1 {
  color: #512e05;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 54.6px */
}
.reach-out p {
  color: #78808a;
  // font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

/* ?//////////////tab/// */
.tab {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f1f1f1;
  width: 209px;
  justify-content: center;
  align-items: center;
  border-radius: 52px 20px 0px 0px;
  background: rgba(249, 249, 249, 0);
  backdrop-filter: blur(63.5px);
  -webkit-backdrop-filter: blur(63.5px);
  text-align: center;
  color: #403f3e;
  text-align: center;
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
}

#tab1Content {
  display: block;
}

.active-tab {
  background: rgba(249, 249, 249, 0.8);

  backdrop-filter: blur(63.5px);
  -webkit-backdrop-filter: blur(63.5px);
}
.tab1 {
  border-radius: 52px 20px 0px 0px;
  padding: 30.324px 0px 30.676px 0px;
}
.tab2 {
  border-radius: 20px 52px 0px 0px;
  padding: 25px 30.5px 21px 30px;
}

/* Style for the tab content */
/* .tab-content {
    display: none;
    border-top: none;
    border-radius: 0px 0 52px 52px;
    background: rgba(249, 249, 249, 0.8);
    backdrop-filter: blur(63.5px);
    -webkit-backdrop-filter: blur(63.5px);
    margin-top: -10px;
  } */
#tab1Content {
  /* display: none; */
  /* padding: 20px; */
  /* border: 1px solid #ccc; */
  border-top: none;
  border-radius: 0px 37.545px 37.545px 37.545px;
  background: rgba(249, 249, 249, 0.8);
  backdrop-filter: blur(45.84877395629883px);
  -webkit-backdrop-filter: blur(63.5px);
  margin-top: -10px;
}
#tab2Content {
  /* display: none; */
  /* padding: 20px; */
  /* border: 1px solid #ccc; */
  border-top: none;
  border-radius: 52px 0 52px 52px;
  background: rgba(249, 249, 249, 0.8);
  backdrop-filter: blur(63.5px);
  -webkit-backdrop-filter: blur(63.5px);
  margin-top: -10px;
}
.tab-content2 {
  // display: none;
  /* padding: 20px; */
  /* border: 1px solid #ccc; */
  border-top: none;
  /* border-radius: 0px 52px 52px 52px; */
  background: rgba(249, 249, 249, 0.8);
  backdrop-filter: blur(63.5px);
  -webkit-backdrop-filter: blur(63.5px);
  margin-top: -10px;
}

.content-inside {
  display: flex;
  padding: 5.535px 30px 46.465px 30px;
  flex-direction: column;
  align-items: center;
  margin-top: -10px;
  height: 100%;
}
.name {
  margin: 0px 0;
}
.name span {
  color: #0f0f0f;
  // font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 17.329px */
  text-transform: capitalize;
}
.name .input {
  border-radius: 6px;
  background: #fff;
  display: flex;
  width: 317px;
  padding: 10px 0.5px 10px 22.5px;
  align-items: center;
  margin: 9px 0;
  outline: none;
  font-size: 14px;
}
.name .input-phone {
  border-radius: 0 14px 14px 0px;
  background: #fff;
  display: flex;
  /* width: 440px; */

  padding: 16px 0.5px 16px 22.5px;

  align-items: center;
  margin: 8px 0;
  font-size: 14px;
}

.name input::placeholder {
  color: #b2b1ad;
  /* font-family: Inter; */
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.name textarea::placeholder {
  color: #b2b1ad;
  /* font-family: Inter; */
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
/* //////////////end tabs */

.flag-dropdown {
  position: relative;
  /* width: 150px; */

  display: flex;
  padding: 11px 13px 11px 9px;
  align-items: center;
  gap: 16px;
  // background: #f5f5f5;
  border-radius: 16px 0 0 16px;
}

.selected-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  /* border: 1px solid #ccc; */
  padding: 5px;
}

.flag-icon {
  display: inline-block;
  width: 24px;
  height: 25px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.flag-icon.us {
  background-image: url("./Contact-assetes/united-states.png");
  width: 40px;
  /* width: 100%; */
  /* height: 100%; */
  /* camera.position.set(0, -20, 10); */
  /* const planeGeometry = new THREE.PlaneGeometry(90, 50, 10, 10); */
}

.flag-icon.uk {
  background-image: url("./Contact-assetes/united-kingdom.png");
}
.flag-icon.in {
  background-image: url("./Contact-assetes/flag-india.png");
}
.flag-icon.ch {
  background-image: url("./Contact-assetes/china.png");
}
.flag-icon.cn {
  background-image: url("./Contact-assetes/france.png");
}
.country-name {
  flex: 1;
}

.flag-drop-down-contact-number {
  position: absolute;
}

.dial-sekect {
  top: 37px;
  select {
    width: 100px;

    display: flex;
    padding: 7.942px 9.386px 7.942px 12.997px;
    align-items: center;
    gap: 11.552px;
    background: #f5f5f5;
  }
}

.phone-contact {
  padding-left: 50px !important;
}

.options-list {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 70%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1;
}

.options-list li {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.options-list li:hover {
  background-color: #f5f5f5;
}
.submit-button .submit-btn {
  display: flex;
  padding: 11px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #3040d0;
  color: #fff;
  /* font-family: Satoshi; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.submit-button .next {
  display: flex;
  padding: 11px 50px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #3040d0;
  color: #fff;
  /* font-family: Satoshi; */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  // width: 127px;
}
.submit-button .submit2 {
  display: flex;
  padding: 11px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #3040d0;
  color: #fff;
  /* font-family: Satoshi; */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form {
  display: none;
}

.form:first-child {
  display: block;
}

.select1 select {
  /* display: flex; */
  padding: 13px 0.534px 13px 22px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background: #fff;
  width: 110px;
  margin: 12px 0;
}
.select2 select {
  /* display: flex; */
  padding: 13px 0.534px 13px 22px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background: #fff;
  width: 188px;
  margin: 12px 0;
  // position: relative;
  z-index: 90;
}

.prev {
  display: flex;
  padding: 20.399px 19.326px;
  justify-content: center;
  align-items: center;
  gap: 19.326px;
  border-radius: 42.946px;
  background: #fff;
}

/* /////////////////end/hero section ?////////////////////////////////////// */

@media (max-width: 400px) {
  .tab {
    width: 175px;
  }
}
