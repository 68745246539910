.start-trial {
  background-color: #f4f7fa;
  width: 100%;
  height: 327px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  .start-div {
    width: 968px;
    height: 153px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .startlink {
      padding: 16px 72px 16px 72px;
      border-radius: 6px;
      gap: 8px;
      background: #3040d0;
      color: #f4f7fa;
    }
    .start-div2 {
      width: 460px;
      height: 153px;
      h1 {
        font-family: Poppins;
        font-size: 42px;
        font-weight: 600;
        line-height: 63px;
        letter-spacing: 0em;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        width: 373.52px;
        margin-top: 20px;
        color: #2c2e32;
      }
    }
  }
}
@media (max-width: 1024px) {
  .start-trial {
    .start-div {
      width: 768px;
      .start-div2 {
        width: 420px;
        h1 {
          font-size: 37px;
          line-height: 55px;
        }
        p {
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .start-trial {
    .start-div {
      flex-direction: column;
      width: 420px;
      height: auto;
      gap: 20px;
      .start-div2 {
        width: 100%;
        height: auto;
        text-align: center;
        h1 {
          font-size: 32px;
          line-height: 45px;
        }
        p {
          width: 90%;
          text-align: center;
          margin-left: 5%;
        }
      }
    }
  }
}
@media (max-width: 420px) {
  .start-trial {
    .start-div {
      width: 310px;
      .start-div2 {
        h1 {
          font-size: 27px;
          line-height: 40px;
        }
      }
      .startlink{
        width: 310px;
      }
    }
  }
}