.hero-faq {
  h1 {
    color: #2c2e32;
    text-align: center;
    // font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  p {
    color: #545050;
    text-align: center;
    // font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 53%;
  }
}

.faq-input {
  input {
    display: flex;
    height: 67px;
    padding: 23.77px 12.518px 22.23px 17px;
    align-items: center;
    align-self: stretch;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.07);
    width: 403px;
    margin-top: 10px;
  }
  textarea {
    display: flex;
    // height: 149px;
    padding: 24.293px 95.773px 24.293px 17px;
    align-items: center;
    align-self: stretch;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.07);
  }
}

.faq-select {
  margin-top: 10px;
  width: 125px;
  display: flex;
  padding: 16px 17px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  background: #fff;

  /* search field */
  box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.07);
}
.faq-size {
  margin-top: 10px;
  width: 239px;
  display: flex;
  padding: 16px 17px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  background: #fff;

  /* search field */
  box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.07);
}

.generator {
  p {
    color: #545050;
    // font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.genrate-btn {
  button {
    display: flex;
    padding: 14px 40px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    background: #3040d0;
    width: 100%;
    color: #fff;
    // font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.right-genrae {
  border-radius: 11px;
  background: #f8f8f8;
  span {
    color: #454545;
    // font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
