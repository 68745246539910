.password-reset-page {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.password-reset-page h1 {
  font-family: Poppins, sans-serif;
  font-size: 32px;
  color: #2c2e32;
  font-weight: 600;
  margin-bottom: 20px;
}

.password-reset-page p {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  color: #737679;
  margin-bottom: 30px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .password-reset-page {
    padding: 15px;
  }

  .password-reset-page h1 {
    font-size: 28px;
  }
}
