thead tr {
  background: #ebf2ff;
  th {
    // display: flex;
    // width: 142px;
    padding: 18px 18px 18px 25px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: #3053d0;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

tbody {
  td {
    // display: flex;
    // width: 142px;
    height: 55px;
    padding: 8px 8px 8px 25px;
    justify-content: space-between;
    align-items: center;
    color: #1d1d1d;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
