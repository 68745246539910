.fish-black {
  color: #2c2e32;
  font-variant-numeric: ordinal;
  font-feature-settings: "dlig" on;
  font-family: Poppins;
  font-size: 106.286px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  //   -webkit-text-stroke: 1px black;
  //   color: white;
  white-space: nowrap;
}
.fish-white {
  color: #2c2e32;
  font-variant-numeric: ordinal;
  font-feature-settings: "dlig" on;
  font-family: Poppins;
  font-size: 106.286px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  -webkit-text-stroke: 1px black;
  color: white;
  white-space: nowrap;
}

.fish-in-pond {
  overflow: hidden;
}

.fish-svg {
  width: 63px;
  height: 56px;
  img {
    width: 63px;
    height: 56px;
  }
}

.fishh {
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-34%));
  }
}

@media (min-width: 800px) and (max-width: 1024px) {
  .fish-in-pond {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .main-cover {
    flex-wrap: wrap;
  }
}
