.head-works {
  margin-left: 72px;
  h3 {
    color: #2c2e32;
    font-variant-numeric: ordinal;
    font-feature-settings: "dlig" on;
    font-family: Poppins;
    font-size: 31px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.progressh-card {
  margin-left: 182px;
  width: 150%;
}
.slides {
  width: 548.25px;
  height: 302.5px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 40px;
  background-color: #fcfcfc;
  border-radius: 21px;
}
.slide-cont {
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 34px;
}
.slide-cont-no {
  font-size: 108px;
  font-weight: 400;
  letter-spacing: 0em;
  color: #3040d0;
  margin: 15px 0 0px 34px;
}
.slide-cont-p {
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 21px;
  color: #3a3a3a;
  margin-left: 34px;
  width: 400px;
}
.slid {
  width: 100%;
  overflow-x: hidden;
}
.slides-fake {
  width: 200px;
}
.swip {
  width: 2100px;
  padding: 10px 150px;
}
.parent-scroll-bar {
  overflow-x: scroll;
  //   width: 150%;
}
.parent-scroll-bar::-webkit-scrollbar {
  display: none;
}
.swiper-pagination-progressbar {
  background-color: #e3e6f9;
}

.swiper-horizontal .swiper-pagination-horizontal {
  height: 7px;
  width: 400px;
  left: 160px;
  top: 365px;
  border-radius: 50px;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #3040d0;
  height: 15px;
  top: -4px;
}
.num-one {
  display: flex;
  padding: 35.715px 52px 53px 34px;
  flex-direction: column;
  align-items: flex-start;
  gap: 39.285px;
  border-radius: 21px;
  background: #fcfcfc;
  width: 548px;
}

.one-number {
  h3 {
    color: #3040d0;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: "dlig" on;
    // font-family: Space Grotesk;
    font-size: 106.286px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.amet-magnis {
  h2 {
    color: #3a3a3a;
    // font-family: "Roboto";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 31.2px */
  }
  p {
    color: #3a3a3a;
    // font-family: "Roboto";
    font-size: 16.384px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 21.3px */
  }
}

.scroll-bar-fill {
  width: 568px;
  height: 10.375px;
  flex-shrink: 0;
}
@media (max-width: 1024px) {
  .box-cover {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .scroll-bar-fill {
    width: 100% !important;
  }
  .progressh-card {
    margin-left: 2px;
    width: 150%;
  }
  .swiper-horizontal .swiper-pagination-horizontal {
    height: 7px;
    width: 160px;
    left: 160px;
  }
  
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    height: 7px;
    top: 0px;
  }
  .slides {
    width: 420px;
  }
  .works-bar {
    padding-left: 0;
  }
  .how-it-works {
    padding: 0 20px;
  }
  .swip{
    width: 1400px;
  }
  .box-cover {
    justify-content: center;
  }

  .what-covers {
    margin-top: 150px;
  }
}
@media (max-width: 768px) {
  .swiper-horizontal .swiper-pagination-horizontal {
    left: 60px;
  }
  .swip{
    padding: 10px 60px
  }
}