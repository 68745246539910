.accordion-header {
  border-radius: 8px;
  background: #f9f9f9;
  display: flex;
  padding: 17px 22px;
  //   flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}

.accordion-item {
  width: 643px;
}

.accordion-content {
  p {
    color: #252525;
    font-variant-numeric: ordinal;
    font-feature-settings: "dlig" on;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.price-faq-heading {
  h1 {
    color: #252525;
    font-variant-numeric: ordinal;
    font-feature-settings: "dlig" on;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.faq-contact-btn {
  color: #272728;
  font-variant-numeric: ordinal;
  font-feature-settings: "dlig" on;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  button {
    color: #3040d0;
    font-variant-numeric: ordinal;
    font-feature-settings: "dlig" on;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
  }
}

@media (max-width: 768px) {
  .accordion-item {
    width: 100%;
    padding: 0 20px;
  }
  .price-faq-heading h1 {
    padding: 20px;
  }
}
