@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}

/* //////////////////Navbar ?////////////////////////////////////// */

nav {
  position: fixed;
  top: 0%;
  height: 64px;
  width: 100%;
  z-index: 99999999999999999999;
}
nav::before {
  content: "";
  width: 100%;
  height: 63px;
  position: absolute;
  // border-radius: 21px;
  background: #fff;
  // background: #fff;
  box-shadow: 0px 4px 24px 1px rgba(0, 0, 0, 0.05);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  z-index: -1;
  top: 0px;
  left: 0%;
}
.logo-img {
  width: 67px;
  height: 24px;
}
.items ul li {
  list-style: none;
}
.items {
  margin: 0 20px;
  color: #2c2e32;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  /* gap: 47px; */
}

.ul {
  display: flex;
  height: 42.802px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 47px;
}

.contact-btn {
  display: flex;
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: #3040d0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Login-btn {
  display: flex;
  padding: 6px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #3040d0;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.logout-btn {
  color: #3040d0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/* //////////////////Navbar ?////////////////////////////////////// */

@media (max-width: 908px) {
  .ul {
    height: 100%;
    // display: none;
  }

  .lists {
    display: none;
  }
  .lists.active {
    display: block;
    position: absolute;
    background: rgba(248, 251, 248, 0.9);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    left: -45px;
    top: 0px;
    width: 100%;
    padding: 40px 0 400px 0;
    // color: alpha($color: #000000);
    // height: 400px;
  }

  .lists .items {
    font-size: 16px;
    margin: 10px;
    color: #000;
  }
  .ul .active {
    height: 100%;
    // display: block;
  }
}

@media (min-width: 768px) and (max-width: 1260px) {
  .items {
    margin: 0 10px;
    font-size: 12px;
  }
}
