.main-head-ft {
  h1 {
    color: #2c2e32;
    font-variant-numeric: ordinal;
    font-feature-settings: "dlig" on;
    font-family: "Poppins";
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    z-index: 999999;
  }
}

.main-ft-hero {
  height: 700px;
  fill: linear-gradient(274deg, #001e89 2.57%, rgba(1, 91, 156, 0) 89.74%);
}

.bg-1 {
  background-image: url("./bg-1.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: right;
}
.bg-2 {
  background-image: url("./bg-2.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: right;
}
.bg-3 {
  background-image: url("./bg-3.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: right;
}
.bg-4 {
  background-image: url("./bg-4.svg");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: right;
  //   transform: rotate(-14.908deg);
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .main-head-ft h1 {
    color: #fff;
    font-size: 55px;
  }
  .main-head-ft {
    padding-left: 30px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .main-ft-hero {
    // height: 799px;
  }
  .bg-1{
    height: 700px;
  }
  .bg-2{
    height: 700px;
  }
  .bg-3{
    height: 700px;
  }
  .bg-4 {
    height: 700px;
  }
}
