/* //////////////////hero ?////////////////////////////////////// */

.main-hero-owner h1 {
  color: #252525;
  text-align: center;
  font-variant-numeric: ordinal;
  font-feature-settings: "dlig" on;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 54.6px */
}

.main-hero-owner p {
  color: #666;
  text-align: center;
  // font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  width: 500.921px;
}
.bg-orange {
  width: 278.897px;
  height: 274.551px;
  flex-shrink: 0;
  border-radius: 278.897px;
  background: #aadaf8;
}
.astonut {
  top: 0;
  left: -20px;
}
.about-detail h5 {
  color: #363636;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
}
.about-detail p {
  width: 245.191px;
  color: #5d5d5d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.img-logos svg {
  margin: 4px;
}
.main-container {
  display: flex;
  padding: 103.5px 185.079px 103.5px 175px;
  align-items: center;
}
.main-container-trail {
  display: flex;
  padding: 87px 180.75px;
  justify-content: center;
  align-items: center;
}
.left-content h1 {
  color: #252525;
  font-variant-numeric: ordinal;
  font-feature-settings: "dlig" on;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 54.6px */
  width: 472.966px;
}
.left-content p {
  color: #666;
  /* font-family: Inter; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  width: 500.921px;
}

.left-content-trail h1 {
  color: #905907;
  text-align: center;
  font-variant-numeric: ordinal;
  font-feature-settings: "dlig" on;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.left-content-trail p {
  color: #29373a;
  /* font-family: Inter; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  width: 373.517px;
}
.trail {
  display: flex;
  padding: 14.25px 44.25px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  background: #f5a302;
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/* //////////////////hero ?////////////////////////////////////// */

// media queries

@media (max-width: 768px) {
  .owner {
    flex-wrap: wrap;
    gap: 20px;
  }
  .main-container {
    padding: 33px;
    flex-wrap: wrap;
  }
  .left-content p {
    width: 100%;
  }
  .main-hero-owner p {
    width: 100%;
  }

  .left-content h1 {
    width: 100%;
    margin: 10px 0;
  }
  .about-detail {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    p{
      text-align: center;
    }
  }
}
